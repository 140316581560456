<template>
  <div class="item" @click="goDetail(item)">

    <img :src="item.cover" alt="新闻封面" class="poster">

    <div class="detail">
      <p class="title">{{item.name}}</p>
      <p class="desc">
        {{item.desc}}
      </p>
      <div class="bottom">
        <div class="date">{{item.date}}</div>
        <div class="controll">
          <i class="wemeet-dianzan1 iconfont "></i>
          <span>{{dianzan}}</span>
          <i class="wemeet-liulan iconfont "></i>
          <span>{{views}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewsBigItem',
  props: {
    item: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {}
  },

  mounted() {},

  computed: {
    views() {
      return Math.floor(Math.random() * 11500)
    },
    dianzan() {
      return Math.floor(Math.random() * 500)
    },
  },

  methods: {
    goDetail(e) {
      this.$router.push({ path: '/newsDetail', query: { id: e.id } })
    },
  },
}
</script>

<style lang="less" scoped>
.item {
  width: 1198px;
  height: 200px;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #dbdce1;
  padding: 18px 20px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: pointer;
  margin-bottom: 30px;
  &:hover {
    box-shadow: 0px 6px 35px 0px rgba(72, 145, 148, 0.15);
    .detail {
    .title {
      color: #66C2C5;
    }
    }
  }
  .poster {
    width: 260px;
    height: 160px;
    background: #d8d8d8;
    border-radius: 8px;
  }
  .detail {
    padding: 10px 13px;
    .title {
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      word-break: break-all;
      overflow: hidden;
    }
    .desc {
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      word-break: break-all;
      overflow: hidden;
      margin-top: 20px;
    }
    .bottom {
      margin-top: 48px;
      display: flex;
      justify-content: space-between;
      .date {
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
      }
      .controll {
        i {
          font-size: 18px;
          color: #999999;
          vertical-align: middle;
        }
        span {
          font-size: 13px;
          color: #999999;
          margin-left: 4px;
          margin-right: 22px;
          display: inline-block;
          &:nth-of-type(2) {
            margin-right: 0;
          }
        }
      }
    }
  }
}
</style>